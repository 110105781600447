export default defineAppConfig({
  footer: {
    fontWeight: "bold",
    divider: {
      background:
        "transparent linear-gradient(90deg, #F26D85 0%, #FA8F3C 33%, #82D9D0 67%, #30A9D9 100%) 0% 0% no-repeat padding-box;",
    },
  },
  teamsVisibility: {
    shopware: false,
    appDev: false,
    AI: true,
    mobileApps: false,
    devOps: true,
  },
  textColorPrimaryBackground: "255,255,255",
  employeeRoleDataContainerClasses: ["elevation-10"],
  projectManagerClasses: ["elevation-10"],
});
